<template>
  <div class="uk-position-relative slide">
    <div class="header">
      <h2 v-if="slide.caption" class="slide__title">{{ slide.caption }}</h2>
    </div>
    <div class="slide__media">
      <div v-if="!slide.src" class="media">
        <!-- Fallback -->
        <img src="fallback.jpg" alt="Asset not found" />
      </div>

      <template v-else>
        <div v-if="slide.type === 'video'" class="media">
          <video
            :data-autoplay="slide.video_autoplay"
            :data-videomute="slide.video_mute"
            class="uk-width-1-1"
            controls
            :poster="_vaData.apiBase + slide.poster"
            :src="_vaData.apiBase + slide.src"
          ></video>
        </div>

        <div v-if="slide.type === 'image'" class="media">
          <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
        </div>

        <div v-if="slide.type === 'pdf'" class="media">
          <div
            class="vx-ratio-box mobile_fullscreen vx-ratio-box-16-9 uk-background-muted"
          >
          <iframe :src="_vaData.apiBase + slide.src" frameborder="0"></iframe>
            <!-- <iframe
              :src="`/presenter-api${slide.src}`"
              frameborder="0"
            ></iframe> -->
          </div>
        </div>

        <div v-if="slide.type === 'iframe'" class="media">
          <div v-if="!isElectron">
            <div
              class="vx-ratio-box mobile_fullscreen vx-ratio-box-16-9 uk-background-muted"
            >
              <iframe :src="slide.src" frameborder="0"></iframe>
            </div>
          </div>
          <div v-else-if="isElectron && slide.src.startsWith('https://webgl.aworld.andocksysteme.visionsbox.de/andocksysteme/')">
            <div
              class="vx-ratio-box mobile_fullscreen vx-ratio-box-16-9 uk-background-muted"
            >
              <iframe :src="slide.src.replace('https://webgl.aworld.andocksysteme.visionsbox.de/andocksysteme/', '/modelviewer')" frameborder="0"></iframe>
            </div>
          </div>
          <div v-else class="media">
            <video
              :data-autoplay="slide.video_autoplay"
              :data-videomute="slide.video_mute"
              class="uk-width-1-1"
              controls
              :poster="_vaData.apiBase + slide.poster"
              :src="_vaData.apiBase + slide.video"
            ></video>
          </div>
        </div>
      </template>
    </div>
    <div v-if="slide.body" class="uk-padding body">
      <vue-markdown>{{ slide.body }}</vue-markdown>
    </div>
  </div>
</template>

<script>
import isElectron from "is-electron";

export default {
  name: "Slide",
  props: {
    slide: Object,
  },
  components: {},
  mounted() {},
  data() {
    return {
      isElectron: isElectron(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped="">
// .media {
//   height: 100%;
// }
.header {
  padding: 15px 20px;
  h2 {
    margin-bottom: 0px;
    color: var(--content_color__title);
    font-size: var(--content_size__title) !important;
  }
}
video {
  height: 79vh;
  @media (min-height: 1000px) {
    height: 70vh;
  }
  @media (max-height: 800px) {
    height: 70vh;
  }
  @media (max-width: 1000px) {
    height: 70vh;
  }
}
.mobile_fullscreen {
  padding-top: 0 !important;
  // height: 100%;
  height: 79vh;
  @media (min-height: 1000px) {
    height: 70vh;
  }
  @media (max-height: 800px) {
    height: 70vh;
  }
  @media (max-width: 1000px) {
    height: 70vh;
  }
}
</style>
