<template lang="html">
  <div>
    <div class="trigger">{{ strings.locations }}</div>
    <div
      ref="dropDown"
      uk-drop="mode: click; animation: uk-animation-slide-bottom-small; offset: 15; pos: top-right;"
      :class="{ dropdown: isMobile }"
    >
      <vue-custom-scrollbar :settings="scrollbar.settings" class="scroll_body">
        <div class="menu uk-box-shadow-small">
          <div
            @click="goToLocation(location.id)"
            class="menu__entry"
            v-for="(location, index) in locationsMenuEntries"
            :key="index"
          >
            {{ location.title }}
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { bus } from "../main";
import UIkit from "uikit";
export default {
  name: "Navigation",
  components: {
    vueCustomScrollbar
  },
  data() {
    return {
      strings: this._vaData.strings,
      locations: this._vaData.locations.sort((a, b) =>
        a.order > b.order ? 1 : -1
      ),
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: true,
          swicher: true
        }
      }
    };
  },
  computed: {
    locationsMenuEntries: function() {
      return this._vaData.locations
        ? this._vaData.locations.filter(l => l.show_in_menu)
        : false;
    },
    isMobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 960px)");
      return isMobile.matches ? true : false;
    }
  },
  methods: {
    goToLocation(id) {
      bus.$emit("go_to_location", id);
      UIkit.drop(this.$refs.dropDown).hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.trigger {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 50;
}
.uk-drop {
  z-index: 49;
  width: auto;
}
.menu {
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  text-align: left;
  padding-left: 40px;

  @media (max-width: 960px) {
    max-width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.scroll_body {
  height: 500px;

  @media (max-width: 960px) {
    height: 100%;
    max-height: 100%;
  }
}

.menu__entry {
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(#e2002b, 0.025);
  }
}
.boldtext {
  font-weight: 600;
}
.dropdown {
  @media (max-width: 960px) {
    width: 100vw;
    left: 0px !important;
    bottom: 50px !important;
    top: auto !important;
    height: calc(100% - 103px - 56.25vw);
  }
}
</style>
