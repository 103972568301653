<template>
  <div class="videoapp">
    <notifications position="bottom right" />

    <div v-if="isError" class="videoapp__error">
      <!-- ERROR -->
      <span class="emoji">😑</span>
      <h1>
        We are sorry!<br />
        Something went wrong.
      </h1>
      <div class="uk-alert-danger" uk-alert>
        {{ isError }}
      </div>
      <p>If this error persists, please contact us.</p>
      <p>
        <a href="mailto:apps@visionsbox.de">apps@visionsbox.de</a>
        <br />
        <a href="tel:+49 7803 9789030">+49 7803 9789030</a>
      </p>
    </div>
    <div v-else-if="isLoading" class="videoapp__loading">
      <!-- LOADER -->
      <div>
        <i uk-spinner="ratio: 3"></i>
      </div>
      <p>Loading ...</p>
    </div>
    <div
      v-else-if="!loggedin && !isElectron"
      class="videoapp__loading login-form-wrapper"
      :style="backgroundUrl ? `background-image: url(${backgroundUrl})` : ''"
    >
      # LOGIN_FORM
      <div>
        <LoginForm
          :uiStrings="strings"
          @onSuccess="onSuccess"
          @onError="displayError"
        />
      </div>
    </div>
    <template v-else>
      <div class="videoapp__main" id="app">
        <!-- APP -->

        <Header />
        <Content />
        <VideoThing :settings="vtSettings" />
        <ContactMenu v-if="!isElectron" />
        <MobileUi />

        <Footer />
      </div>
      <Offcanvas />
      <SlideOut />
    </template>
  </div>
</template>

<script>
import VideoThing from "./components/videoThing/VideoThing.vue";
import Offcanvas from "./components/Offcanvas.vue";
import Content from "./components/content/index.vue";
import SlideOut from "./components/content/SlideOut.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import MobileUi from "./components/mobileUi/MobileUi.vue";
import ContactMenu from "./components/ContactMenu.vue";
import LoginForm from "./components/LoginForm.vue";
import isElectron from "is-electron";
import { bus } from "./main";

export default {
  name: "App",
  components: {
    VideoThing,
    SlideOut,
    Content,
    Header,
    Footer,
    MobileUi,
    ContactMenu,
    Offcanvas,
    LoginForm,
  },
  metaInfo() {
    return {
      title: this.strings ? this.strings.app_title : "Loading ...",
    };
  },
  data: function () {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      isLoading: false,
      loggedin: false,
      isError: false,
      vtSettings: false,
      isElectron: isElectron(),
    };
  },
  computed: {
    contentMenuEntries: function () {
      return this._vaData.contents
        ? this._vaData.contents.filter((c) => c.show_in_menu)
        : false;
    },
    locationsMenuEntries: function () {
      return this._vaData.locations
        ? this._vaData.locations.filter((l) => l.show_in_menu)
        : false;
    },
    backgroundUrl() {
      if (!this._vaData.settings?.login_page_background?.url) return "";
      return (
        this._vaData.apiBase + this._vaData.settings?.login_page_background?.url
      );
    },
  },
  watch: {
    // https://stackoverflow.com/a/46331968/1308363
    "settings.show_hotspots": {
      handler(val) {
        if (val) {
          bus.$emit("show_hotspots");
        } else {
          bus.$emit("hide_hotspots");
        }
      },
    },
    "settings.show_navigation": {
      handler(val) {
        if (val) {
          bus.$emit("show_navigation");
        } else {
          bus.$emit("hide_navigation");
        }
      },
    },
  },
  methods: {
    main() {
      this.setCssVars();
    },
    async fetchData() {
      console.log("🔄 Loading data ...");
      const apiBase = process.env.VUE_APP_API_BASE;
      this.isLoading = true;

      // 0. Check if the user is logged in
      const isLoggedIn = await this.isLoggedIn();
      if (isLoggedIn) {
        this.loggedin = true;
      }

      // 1. locale selection
      await fetch(apiBase + "/i18n/locales")
        .then((r) => r.json())
        .then((r) => {
          this._vaData.availableLocales = r;
          this._vaData.currentLocale = r.find((i) => i.isDefault);
        });

      const params = new URLSearchParams(document.location.search);
      const urlLocale = params.get("locale");
      const foundLocale = this._vaData.availableLocales.find(
        (l) => l.code === urlLocale
      );
      let locale = this._vaData.currentLocale.code;
      if (urlLocale && foundLocale) {
        // use url locale
        locale = urlLocale;
        this._vaData.currentLocale = foundLocale;
      } else {
        // use default(current) locale -> nothing to do here
      }

      console.log("🏳️‍🌈 Language: " + locale);

      // 2. load localized data
      const urls = [
        apiBase + "/locations?_locale=" + locale,
        apiBase + "/destinations",
        apiBase + "/settings",
        apiBase + "/contents?_locale=" + locale,
        apiBase + "/strings?_locale=" + locale,
      ];

      const requests = urls.map((url) => fetch(url));

      const apiData = await Promise.all(requests)
        .then((responses) => Promise.all(responses.map((r) => r.json())))
        .catch((reason) => {
          console.log(reason);
        });

      this._vaData.locations = this.locations = apiData[0];
      this._vaData.destinations = this.destinations = apiData[1];
      this._vaData.settings = this.settings = apiData[2];
      this._vaData.contents = this.contents = apiData[3];
      this._vaData.strings = this.strings = apiData[4];

      console.log("👨🏼‍🔧 Settings", this._vaData.settings);
      console.log("🏝️ Locations: ", this._vaData.locations);
      console.log("✈️ Destinations: ", this._vaData.destinations);
      console.log("📚 Contents: ", this._vaData.contents);
      console.log("📄 Strings: ", this._vaData.strings);

      if (!this.locations.length) {
        throw new Error("No location found!");
      }

      this.init();
      this.main();
      this.isLoading = false;
    },
    init() {
      console.log("📽️ Starting videoThing");
      const params = new URLSearchParams(window.location.search);
      const defaultStartLocation = this.settings.start_location
        ? this.settings.start_location
        : this._vaData.locations[0].id;

      const startLocation = params.get("location")
        ? params.get("location")
        : defaultStartLocation;

      this.vtSettings = {
        startLocation: startLocation,
        screensaver: null,
        preloadDestinationsAtStart: false,
        assetBase: process.env.VUE_APP_API_BASE,
        transitionOverlay: {
          color: "#000",
          transitionDuration: 1000,
        },
      };

      window.addEventListener("load", function () {
        setTimeout(function () {
          // This hides the address bar:
          window.scrollTo(0, 1);
        }, 0);
      });
    },
    setCssVars() {
      // Header Options
      document
        .querySelector("body")
        .style.setProperty(
          "--logo_width",
          this.settings.header_options.logo_width + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--header_bar__color",
          this.settings.header_options.header_bar__color
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--title_size",
          this.settings.header_options.font_size__title + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--font_weight",
          this.settings.header_options.font_weight
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--font_color__title",
          this.settings.header_options.font_color__title
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--font_color__highlight",
          this.settings.header_options.font_color__highlight
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--font_title_transform",
          this.settings.header_options.font_title_transform
        );
      // Footer and Contact Options
      document
        .querySelector("body")
        .style.setProperty(
          "--footer_color",
          this.settings.footer_options.footer_color
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--footer_color__hover",
          this.settings.footer_options.footer_color__hover
        );
      // Videothing Options
      document
        .querySelector("body")
        .style.setProperty(
          "--font_size__button",
          this.settings.videothing_options.font_size__button + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--font_weight",
          this.settings.videothing_options.font_weight
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_size_transform",
          this.settings.videothing_options.button_size_transform
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_color__font",
          this.settings.videothing_options.button_color__font
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_hover__font",
          this.settings.videothing_options.button_hover__font
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_color__navigation",
          this.settings.videothing_options.button_color__navigation
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_border__navigation",
          this.settings.videothing_options.button_border__navigation
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_hover__navigation",
          this.settings.videothing_options.button_hover_navigation
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_color__hotspot",
          this.settings.videothing_options.button_color__hotspot
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_border__hotspot",
          this.settings.videothing_options.button_border__hotspot
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_hover__hotspot",
          this.settings.videothing_options.button_hover_hotspot
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_bar__color",
          this.settings.videothing_options.content_bar__color
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_slide__color",
          this.settings.videothing_options.content_slide__color
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_size__title",
          this.settings.videothing_options.content_size__title + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_size__slide",
          this.settings.videothing_options.content_size__slide + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_size__body",
          this.settings.videothing_options.content_size__body + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_height",
          this.settings.videothing_options.button_height + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_size_normal",
          this.settings.videothing_options.button_size_normal + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_size_small",
          this.settings.videothing_options.button_size_small + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_size_big",
          this.settings.videothing_options.button_size_big + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--button_size_bigger",
          this.settings.videothing_options.button_size_bigger + "px"
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_color__body",
          this.settings.videothing_options.content_color__body
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_color__title",
          this.settings.videothing_options.content_color__title
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_title_transform",
          this.settings.videothing_options.content_title_transform
        );
      document
        .querySelector("body")
        .style.setProperty(
          "--content_color__h",
          this.settings.videothing_options.content_color__h
        );
    },
    async isLoggedIn() {
      try {
        const token = window.localStorage.getItem("vb");
        if (!token) return false;
        // Headers
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${token}`);
        // Check user
        const resData = await fetch(
          `${process.env.VUE_APP_API_BASE}/users/me`,
          {
            method: "GET",
            headers: headers,
          }
        );
        if (resData.status === 200) {
          this.loggedin = true;
          return true;
        }
      } catch (error) {
        // console.log(error);
      }
      window.localStorage.setItem("vb", "");
      return false;
    },
    onSuccess(message) {
      this.loggedin = true;
      this.displayMessage(message);
    },
    displayMessage(message) {
      if (!message) return;
      this.$notify({
        type: "success",
        text: message,
      });
    },
    displayError(message) {
      if (!message) return;
      this.$notify({
        type: "error",
        text: message,
      });
    },
  },
  async created() {
    bus.$on("location_reached", (id) => {
      const location = this._vaData.locations.find((l) => l.id === id);
      console.info(
        `➡️ You reached location: ${location.title} (ID: ${id})`,
        location
      );

      const params = new URLSearchParams(window.location.search);
      params.set("location", id);
      window.history.replaceState({}, "", `?${params}`);
    });

    bus.$on("location_left", (id) => {
      const location = this._vaData.locations.find((l) => l.id === id);
      console.info(`⬅️ You left location: ${location.title} (ID: ${id})`);
    });

    await this.fetchData();
  },

  mounted() {},
};
</script>

<style lang="scss">
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
@import "./videoapp.scss";

body {
  &.is-mobile {
    @media screen and (orientation: landscape) {
      position: relative;

      &:before {
        content: "";
        z-index: 99999;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $global-emphasis-color;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        background-image: url("./assets/rotate.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: 99999;
      }
    }
  }
  &.is-tablet {
    @media screen and (orientation: portrait) {
      position: relative;

      &:before {
        content: "";
        z-index: 99999;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $global-emphasis-color;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        background-image: url("./assets/rotate.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: 99999;
      }
    }
  }
}

.home-btn {
  width: 2em;
  margin-left: 0.5em;
  background-image: url("./assets/haus.svg");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
}

.uk-dropdown {
  background: var(--font_color__highlight);
  color: #fff;
}
.login-form-wrapper {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, center;
}
</style>
