import Vue from "vue";
// import VueMobileDetection from 'vue-mobile-detection';
import App from "./App.vue";
import VModal from "vue-js-modal";
import Notifications from "vue-notification";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Meta from "vue-meta";

import {
  isMobileOnly
} from "mobile-device-detect";
import {
  isTablet
} from "mobile-device-detect";
import {
  isIOS
} from "mobile-device-detect";

import {
  install as VueMarkdown
} from "vue-markdown/src/build";
UIkit.use(Icons);
Vue.use(VueMarkdown);
Vue.use(VModal);
Vue.use(Notifications);

// Vue.use(VueMobileDetection);

Vue.use(Meta);
Vue.config.productionTip = false;

if (isMobileOnly) {
  document.querySelector("body")
    .classList.add("is-mobile");
  console.log("mobile device");
} else if (isTablet) {
  document.querySelector("body")
    .classList.add("is-tablet");
  console.log("tablet device");
} else {
  // false for not mobile device
  console.log("not mobile device");
}

if (isIOS) {
  document.querySelector("body")
    .classList.add("is-ios");
  console.log("ios device");
}

// _vaData is filled after initial ajax-calls
// based on: https://stackoverflow.com/a/43193455/1308363
const _vaData = new Vue();

_vaData.install = function () {
  Object.defineProperty(Vue.prototype, "_vaData", {
    get() {
      return _vaData;
    },
  });
};

Vue.use(_vaData);
_vaData.apiBase = process.env.VUE_APP_API_BASE;

// global event bus
// based on: https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/
export const bus = new Vue();

new Vue({
    render: (h) => h(App),
  })
  .$mount("#app");