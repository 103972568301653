<template>
  <div class="uk-width-1-1@m ">
    <div
      class="uk-margin login_form uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large"
    >
      <h3 class="uk-card-title uk-text-center">
        {{ uiStrings.login_form_title }}
      </h3>
      <div class="uk-margin">
        <!-- ----- -->
        <!-- Email -->
        <!-- ----- -->

        <div class="uk-inline uk-width-1-1">
          <span class="uk-form-icon" uk-icon="icon: mail"></span>
          <input
            v-model="email"
            class="uk-input uk-form-large"
            type="text"
            :placeholder="uiStrings.login_email"
          />
        </div>
      </div>
      <div class="uk-margin">
        <!-- -------- -->
        <!-- Password -->
        <!-- -------- -->
        <div class="uk-inline uk-width-1-1">
          <span class="uk-form-icon" uk-icon="icon: lock"></span>
          <input
            v-model="password"
            class="uk-input uk-form-large uk-placeholder"
            type="password"
            :placeholder="`${uiStrings.login_password}`"
            @keyup.enter="login"
          />
        </div>
      </div>
      <div class="uk-margin">
        <!-- ------ -->
        <!-- Button -->
        <!-- ------ -->
        <button
          class="uk-button uk-button-primary uk-button-large uk-width-1-1"
          @click="login"
        >
          {{ uiStrings.login_button }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    uiStrings: Object,
  },
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    async login() {
      this.error = null;

      // Credential
      const data = {
        identifier: this.email,
        password: this.password,
      };

      // Headers
      let headers = new Headers();
      headers.append("Content-Type", "application/json");

      try {
        if (!this.password || !this.email) {
          this.$emit("onError", this.uiStrings.login_error_credential);
          return;
        }
        // Login
        const resData = await fetch(
          `${process.env.VUE_APP_API_BASE}/auth/local`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );

        if (resData.status === 200) {
          // Success

          const { jwt } = await resData.json();
          this.$emit("onSuccess", this.uiStrings.login_success);

          // Save JWT to the store
          window.localStorage.setItem("vb", jwt);
        } else if (resData.status === 400) {
          // Error: credential

          const error = await resData.json();

          if (error?.message[0].messages[0]?.id === "Auth.form.error.blocked") {
            this.$emit("onError", this.uiStrings.login_blocked);
          } else if (
            error?.message[0].messages[0]?.id ===
              "Auth.form.error.email.provide" ||
            error?.message[0].messages[0]?.id === "Auth.form.error.invalid"
          ) {
            this.$emit("onError", this.uiStrings.login_error_credential);
          }

          window.localStorage.setItem("vb", "");
        } else {
          // Error: server

          this.$emit("onError", this.uiStrings.login_error_server);
          window.localStorage.setItem("vb", "");
        }
      } catch (error) {
        // Error: server

        this.$emit("onError", this.uiStrings.login_error_server);
        window.localStorage.setItem("vb", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uk-form-icon {
  color: #aaa !important;
}
.login_form {
  width: 450px;

  @media (max-width: 960px) {
    width: 100%;
  }
}
</style>
